import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlayerContext } from '../contexts/PlayerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../assets/css/PersistentPlayer.css';

function PersistentPlayer() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    currentTrack,
    isPaused,
    playerRef,
  } = useContext(PlayerContext);

  const handleContainerClick = (e) => {
    // Avoid navigating when clicking the play/pause button
    if (e.target.closest('.play-pause-button')) {
      return;
    }

    // Navigate to `/player` if not already there
    if (location.pathname !== '/player') {
      navigate('/player');
    }
  };

  return (
    <div className="persistent-player bg-dark text-white p-2 d-flex justify-content-between align-items-center" onClick={handleContainerClick} style={{ cursor: 'pointer' }}>
      <div className="track-info">
        {currentTrack ? (
          <>
            <strong>{currentTrack.name}</strong> -{' '}
            {currentTrack.artists?.[0]?.name || 'Unknown Artist'}
          </>
        ) : (
          'No track playing'
        )}
      </div>
      <div className="player-controls">
        {currentTrack ? (
          <>

            <button
              className={`btn btn-lg ${isPaused ? 'btn-success' : 'btn-danger'} mx-2`}
              onClick={() => playerRef.current?.togglePlay()}
            >
              {isPaused ? 'Play' : 'Pause'}
              <FontAwesomeIcon icon={isPaused ? 'play' : 'pause'} className="ms-2" />


            </button>

          </>
        ) : (
          <span>Player inactive</span>
        )}
      </div>
    </div>
  );
}

export default PersistentPlayer;
