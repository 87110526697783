import React, { useState, useEffect, useRef, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { PlayerProvider } from './contexts/PlayerContext';
import SiteLogin from './components/SiteLogin';
import PersistentPlayer from './components/PersistentPlayer';
import Navbar from './components/navbar/navbar';
import useSiteAccess from './hooks/useSiteAccess';
import DismissAlert from './components/component/DismissAlert';


import './fontawesome'; // This initializes the library
import './App.css';

const HomePage = lazy(() => import('./components/HomePage'));
const WebPlayback = lazy(() => import('./components/WebPlayback'));
const SongForm = lazy(() => import('./components/SongForm'));
const ImportPlaylist = lazy(() => import('./components/ImportPlaylist'));
const ProductList = lazy(() => import('./pages/ProductList'));

function App() {
  const { siteAccessGranted, loading } = useSiteAccess();
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState(null); // Store the user ID
  const [songs, setSongs] = useState([]); // Store songs for the playlist
  const [currentTrack, setCurrentTrack] = useState(null); // Track currently playing song
  const playerRef = useRef(null); // Ref for Spotify Player

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('info'); // 'success', 'danger', etc.



  // Fetch the access token and user information
  useEffect(() => {
    async function getToken() {
      if (token || !siteAccessGranted) return; // Avoid refetching token (either has token or isn't siteAccessGranted)
      try {
        const response = await fetch('/auth/token');
        if (response.status === 401) {
          setAlertMessage('Spotify session expired. Please login.');
          setAlertType('danger');
          return;
        }
        const json = await response.json();
        setToken(json.access_token);

        // Fetch user profile
        const profileResponse = await fetch('/api/spotify/me', {
          headers: { Authorization: `Bearer ${json.access_token}` },
        });
        if (profileResponse.ok) {
          const profileData = await profileResponse.json();
          setUserId(profileData.id);
        } else {
          setAlertMessage('Session expired during profile fetch.');
          setAlertType('danger');
        }
      } catch (error) {
        setAlertMessage(`Error fetching token or profile: ${error.message}`);
        setAlertType('danger');
      }
    }

    getToken();
  }, [token, siteAccessGranted]);

  // Fetch the user's songs
  useEffect(() => {
    async function fetchSongs() {
      if (userId && siteAccessGranted) {
        try {
          const response = await fetch(`/api/songs?userId=${userId}`);
          if (response.status === 401) {
            setAlertMessage('Session expired while fetching songs.');
            setAlertType('danger');
            return;
          }
          const data = await response.json();
          setSongs(data);
        } catch (error) {
          setAlertMessage(`Error fetching songs: ${error.message}`);
          setAlertType('danger');
        }
      }
    }

    fetchSongs();
  }, [userId, siteAccessGranted]);

  // useEffect(() => {
  //   console.log('React siteAccessGranted:', siteAccessGranted);
  //   console.trace('useEffect called');
  // }, [siteAccessGranted]);

  if (loading) {
    // Show a loading spinner or blank screen while checking site access
    return <div className="bg-warning text-white text-center py-2 h5">
      Loading...
    </div>;
  }

  return (
    <Router>
      <div>
        {/* Navbar is always visible */}
        <Navbar siteAccessGranted={siteAccessGranted} token={token} tagLine={false} navLight={true} bg={true} />

        {/* Global DismissAlert */}
        <DismissAlert />

        {/* App Routes */}
        {siteAccessGranted ? ( // Check if site password is authenticated
          token ? (
            <PlayerProvider token={token}>
              <PersistentPlayer /> {/* Render only when wrapped in PlayerProvider */}
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/player"
                  element={
                    <React.Suspense fallback={<div>Loading Player...</div>}>
                      <WebPlayback
                        token={token}
                        songs={songs}
                        setSongs={setSongs}
                        playerRef={playerRef}
                        currentTrack={currentTrack}
                        setCurrentTrack={setCurrentTrack}
                      />
                    </React.Suspense>
                  }
                />
                <Route path="/manage-songs" element={<SongForm token={token} songs={songs} setSongs={setSongs} />} />
                <Route path="/import-playlist" element={<ImportPlaylist token={token} userId={userId} />} />
                <Route path="/shop" element={<ProductList />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </PlayerProvider>
          ) : (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/shop" element={<ProductList />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )
        
        ) : (
          <Routes>
            <Route path="/site-login" element={<SiteLogin />} />
            <Route path="*" element={<Navigate to="/site-login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
