// src/contexts/PlayerContext.js
import React, { createContext, useState, useRef, useEffect, useCallback } from 'react';

export const PlayerContext = createContext();

export const PlayerProvider = ({ children, token }) => {
  const [currentTrack, setCurrentTrack] = useState(null);
  const [recentlyPlayed, setRecentlyPlayed] = useState(new Set());

  const [isPaused, setPaused] = useState(false);
  const [isActive, setActive] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const playerRef = useRef(null);
  const [playlist, setPlaylist] = useState([]); // Current playlist
  const [currentIndex, setCurrentIndex] = useState(null); // Current song index

  // **Activate Device Function**
  const activateDevice = useCallback(async (deviceId) => {
    if (!deviceId || !token) {
      console.error('Cannot activate device without deviceId or token');
      return;
    }

    try {
      const response = await fetch('https://api.spotify.com/v1/me/player', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          device_ids: [deviceId],
          play: false,
        }),
      });

      if (!response.ok) {
        console.error('Error activating device:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error activating device:', error);
    }
  }, [token]);

  const playSong = async (song) => {
    if (!song?.spotify_track_id) {
      console.error('Invalid Spotify Track ID', song);
      return;
    }

    try {
      console.log(`Playing song: ${song.track_name} by ${song.artist_name}`);

      // Call Spotify API to play the song
      const response = await fetch('https://api.spotify.com/v1/me/player/play', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uris: [`spotify:track:${song.spotify_track_id}`],
          position_ms: (song.start_time || 0) * 1000, // Convert seconds to ms
        }),
      });

      if (response.status === 401) {
        console.error('Failed to play song (playSong): Unauthorized');

        // Handle re-authentication
        console.error('Your Spotify session has expired. Please re-authenticate.');

        // Redirect user to re-authenticate with Spotify
        window.location.href = '/auth/login';
      } else if (!response.ok) {
        console.error('Failed to play song (playSong):', response.statusText);
        console.error(`Error playing song: ${response.statusText}`);
      } else {
        // console.log('Song is now playing.');
        setCurrentTrack(song); // Update currentTrack state

        // Add song to recentlyPlayed
        setRecentlyPlayed((prev) => {
          const updatedRecentlyPlayed = new Set(prev);
          updatedRecentlyPlayed.add(song.id);
          return updatedRecentlyPlayed;
        });

        // Call the API endpoint to update last_played_at
        fetch('/api/songs/play', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: song.id }), // Send the song ID in the request body
        }).then(apiResponse => {
          if (!apiResponse.ok) {
            console.error('Failed to update last_played_at:', apiResponse.statusText);
          }
        }).catch(apiError => {
          console.error('Error updating last_played_at:', apiError);
        });
      }
    } catch (error) {
      console.error('Error playing song (playSong):', error);
      console.error(`Unexpected error playing song: ${error.message}`);
    }
  };


  useEffect(() => {
    if (!window.Spotify) {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;
      script.onload = () => {
        if (window.Spotify) {
          // console.log('Spotify SDK loaded.');
        } else {
          console.error('Spotify SDK did not initialize correctly.');
        }
      };
      script.onerror = () => console.error('Failed to load Spotify Web Playback SDK.');
      document.body.appendChild(script);
    }

    window.onSpotifyWebPlaybackSDKReady = () => {
      if (!playerRef.current) {
        playerRef.current = new window.Spotify.Player({
          name: 'Hockey DJ Web Player',
          getOAuthToken: (cb) => { cb(token); },
          volume: 0.5,
        });

        playerRef.current.addListener('ready', ({ device_id }) => {
          setDeviceId(device_id); // Store the device ID
          activateDevice(device_id); // **Call activateDevice when player is ready**
          // console.log("Tried to activate device_id:", device_id);
          // console.log("TOKEN:", token);
        });

        playerRef.current.addListener('not_ready', ({ device_id }) => {
          console.log('Player not ready with device ID:', device_id);
        });

        playerRef.current.addListener('player_state_changed', (state) => {
          if (!state) {
            setActive(false);
            return;
          }
          setActive(true); // Mark as active
          setCurrentTrack(state.track_window.current_track);
          setPaused(state.paused);
        });

        playerRef.current.connect().then((success) => {
          if (!success) {
            console.error('Player failed to connect. Ensure the token and network conditions are correct.');
          }
        }).catch((err) => {
          console.error('Error during player connection:', err);
        });
      }
    };

    return () => {
      if (playerRef.current) {
        playerRef.current.disconnect();
      }
    };
  }, [token, activateDevice]);

  return (
    <PlayerContext.Provider
      value={{
        isPaused,
        setPaused,
        isActive,
        setActive,
        currentTrack,
        setCurrentTrack,
        playerRef,
        deviceId,
        activateDevice,
        playSong,
        playlist,
        setPlaylist,
        currentIndex,
        setCurrentIndex,
        recentlyPlayed,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
