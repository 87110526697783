import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import DismissAlert from './component/DismissAlert'; // Adjust the path based on your project structure

function SiteLogin() {
  const { setSiteAccessGranted } = useAuth();
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('info'); // 'success', 'danger', etc.

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/site-login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
        credentials: 'include', // Ensures cookies are sent
      });
      console.log(response)
      if (response.ok) {
        setAlertMessage('Login successful!');
        setAlertType('success');
        console.log('response is ok')
        setSiteAccessGranted(true);
        localStorage.setItem('siteAccessGranted', true); // Optional: Use cookies if backend supports it
        window.location.reload();
      } else {
        const error = await response.json();

        setAlertMessage(error.message || 'Login failed');
        setAlertType('danger');
      }
    } catch (err) {
      setAlertMessage('Network error. Please try again.');
      setAlertType('danger');
    }
  };

  return (
    <section className='section'>
    <div className="container mt-5">

      {alertMessage && (
        <DismissAlert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage(null)}
        />
      )}
      <h1>Site Login</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">Enter Site Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            autoComplete='off'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Submit
        </button>
      </form>
    </div>
    </section>
  );
}

export default SiteLogin;
