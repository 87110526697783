import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import LogoutButton from '../LogoutButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Navbar({siteAccessGranted, token, tagLine, navLight, bg}) {
  let[toggle, setToggle] = useState(false)

  const location = useLocation(); // Get the current location

  // Handle navigation item click
  const handleNavItemClick = () => {
    setToggle(false); // Close the mobile menu
  };

  return (
    <>
      <header id="topnav" className={`defaultscroll sticky nav-sticky ${bg ? 'bg-white' : ''}`}>
        <div className="container">
          <div>
            <Link className="logo" to='/'>Hockey DJ</Link>
          </div>
          <div className="menu-extras">
            <div className="menu-item">
              <Link className={`navbar-toggle ${toggle ? 'open' : ''}`} id="isToggle" onClick={()=>setToggle(!toggle)}>
                <div className="lines">
                  <FontAwesomeIcon icon='bars' />
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation" className={toggle ? 'd-block':'d-none'}>
            <ul className={`navigation-menu ${navLight ? 'nav-light' : ''}`}>
              {siteAccessGranted ? (
                token ? (
                  <>
                    <li className={location.pathname === '/player' ? 'active' : ''}><Link to="/player" className="sub-menu-item" onClick={handleNavItemClick}>Player</Link></li>
                    <li className={location.pathname === '/manage-songs' ? 'active' : ''}><Link to="/manage-songs" className="sub-menu-item" onClick={handleNavItemClick}>Manage Songs</Link></li>
                    <li className={location.pathname === '/shop' ? 'active' : ''}><Link to="/shop" className="sub-menu-item" onClick={handleNavItemClick}>Shop</Link></li>
                    <li><LogoutButton /></li>
                  </>
                ) : (
                <>
                  <li className={location.pathname === '/shop' ? 'active' : ''}><Link to="/shop" className="sub-menu-item" onClick={handleNavItemClick}>Shop</Link></li>
                  <li><a href="/auth/login" className="bstn bstn-success" onClick={handleNavItemClick}>Login with Spotify</a></li>
                  <li><LogoutButton /></li>
                </>
                )
                ) : (
                <>
                  <li>
                    <a href="/site-login">
                      <span className="badge bg-danger text-white">Please authenticate</span>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}
