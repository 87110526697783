import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [siteAccessGranted, setSiteAccessGranted] = useState(() => {
    // Load initial state from localStorage
    const savedState = localStorage.getItem('siteAccessGranted');
    return savedState === 'true'; // Convert string back to boolean
  });

  useEffect(() => {
    // Save state to localStorage whenever it changes
    localStorage.setItem('siteAccessGranted', siteAccessGranted);
  }, [siteAccessGranted]);

  return (
    <AuthContext.Provider value={{ siteAccessGranted, setSiteAccessGranted }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
