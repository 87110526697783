import { useState, useEffect } from 'react';

const useSiteAccess = () => {
  const [siteAccessGranted, setSiteAccessGranted] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSiteAccess = async () => {
      try {
        const response = await fetch('/api/site-access', {
          credentials: 'include', // Ensure cookies are sent
        });
        // console.log('Raw response from /api/site-access:', response);

        if (!response.ok) {
          throw new Error(`Failed to fetch site access: ${response.statusText}`);
        }

        const contentType = response.headers.get('Content-Type') || '';
        if (!contentType.includes('application/json')) {
          throw new Error('Invalid content type received from server');
        }

        const data = await response.json();
        // console.log('Parsed JSON from /api/site-access:', data);

        setSiteAccessGranted(data.siteAccessGranted);
      } catch (error) {
        console.warn('Checking site access:', error);
        setSiteAccessGranted(false); // Default to false
      } finally {
        setLoading(false);
      }
    };

    fetchSiteAccess();
  }, []);

  return { siteAccessGranted, loading };
};

export default useSiteAccess;
